<script>
import { Page, Sort, GridComponent, ColumnsDirective, Toolbar } from '@syncfusion/ej2-vue-grids'
import { getLabMails } from '@/api/labs'
export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective
  },
  provide: {
    grid: [Page, Sort, Toolbar]
  },
  data() {
    return {
      labNo: '',
      currentPath: this.$route.fullPath, // 현재 접속 경로
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      format: { type: 'dateTime', format: 'y/M/d' },
      toolbar: ['Search'],
      listData: []
    }
  },

  async mounted() {
    this.labNo = this.$route.params.labId
    const mailList = await getLabMails(this.labNo)

    this.listData = mailList
  },

  methods: {
    goBack() {
      this.$router.push('/admin')
    },
    rowSelected(e) {
      const url = `${this.$route.fullPath}/${e.data.mailId}`

      this.$router.push(url)
    }
  }
}
</script>

<template>
  <div>
    <div class="wf__btn-area">
      <button type="button" class="e-btn wf__btn btn-primary" @click="goBack">뒤로가기</button>
    </div>

    <div class="col-lg-12 control-section">
      <ejs-grid
        :dataSource="listData"
        :allowPaging="true"
        :allowSorting="true"
        :pageSettings="pageSettings"
        :toolbar="toolbar"
        :rowSelected="rowSelected"
      >
        <e-columns>
          <e-column field="mailId" headerText="번호" width="100" textAlign="center" />
          <e-column field="labNo" headerText="연구실번호" width="120" textAlign="center" />
          <e-column field="email" headerText="보낸사람" width="250" textAlign="center" />
          <e-column field="createdAt" headerText="발송일" width="200" :format="format" type="date" textAlign="center" />
          <e-column field="title" headerText="제목" textAlign="left" />
          <e-column field="questionType" headerText="문의 목적" textAlign="left" />
          <e-column field="status" headerText="진행상태" width="120" textAlign="center" :disableHtmlEncode="false" />
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>

<style scoped>
.wf__btn-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  margin-top: 0;
}
</style>
